<template>
<b-card v-if="users" no-body class="card-company-table">
    <vue-good-table theme="polar-bear" :rows="users" :columns="fields" :line-numbers="true" :sort-options="{
    enabled: false,
  }">>
        <div slot="emptystate" class="text-nowrap text-center">
            Kayıt Bulunamadı
        </div>
        <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'createdAt'" class="text-nowrap">
                <span class="font-weight-bolder mb-12">{{ props.row.createdAt | moment("DD.MM.YYYY hh:mm")}}</span>
            </div>
            <div v-else-if="props.column.field === 'isActive'" class="text-nowrap">
                <b-badge pill :variant="props.row.isActive ? 'light-success' : 'light-danger'">
                    {{ props.row.isActive ? "AKTİF" : "PASİF" }}
                </b-badge>
            </div>
            <div v-else-if="props.column.field === 'actions'" class="text-nowrap">
                <feather-icon :id="`package-row-${props.row.id}-detail-icon`" icon="MoreHorizontalIcon" size="16" class="mx-1" @click="editUser(props.row)" />
                <b-button  v-if="props.row.userPurchaseType=='INDIVIDUAL'"  variant="primary" @click="editStudentLessonsPanelShow(props.row)">
                    <feather-icon icon="EditIcon" />
                </b-button>
            </div>
            

        </template>
    </vue-good-table>
    <!--Add Teacher Lesson Class--->
 
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addUserPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Kullanıcı Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Sınıf ?" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(branch) => branch.id" :options="branchs" v-model="addUserRequest.branchId" />
                </b-form-group>

                <b-form-group label="Kullanıcı Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addUserRequest.firstName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Soyadı" label-for="surname">
                    <b-form-input name="lastName" id="surname" type="text" v-model="addUserRequest.lastName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Numarası" label-for="code">
                    <b-form-input id="code" type="text" v-model="addUserRequest.idNumber" />
                </b-form-group>
                <b-form-group label="Öğrenci Numarası" label-for="code">
                    <b-form-input id="code" type="text" v-model="addUserRequest.schoolNumber" />
                </b-form-group>
                <b-form-group label="E-Posta Adresi" label-for="email">
                    <b-form-input id="email" type="email" v-model="addUserRequest.email" />
                </b-form-group>

                <b-form-group label="Telefon Numarası" label-for="number">
                    <b-input-group>
                        <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
                        <cleave id="phone" v-model="addUserRequest.gsmNumber" trim class="form-control" :raw="false" :options="{
                  phone: true,
                  phoneRegionCode: 'TR',
                }" placeholder="5455948640" />
                    </b-input-group>
                </b-form-group>

                <b-form-group label="Şifre" label-for="password">
                    <b-form-input id="password" type="password" v-model="addUserRequest.password" />
                </b-form-group>

                <b-form-group label="Cinsiyesi" label-for="from">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(gender) => gender.name" label="name" :options="gender" v-model="addUserRequest.gender" />
                </b-form-group>
                <b-form-group label="Bilgilendirme" label-for="from">
                    <b-form-checkbox v-model="addUserRequest.campaignEmail">
                        E-Posta Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="addUserRequest.campaignSms">
                        Sms Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="addUserRequest.campaignPhone">
                        Aranarak Bilgilendirme
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group label="Bilgilendirme" label-for="some-radios">
                     <b-form-radio v-model="addUserRequest.studentType"  name="some-radios" value="0">Kurumsal Üye</b-form-radio>
                     <b-form-radio v-model="addUserRequest.studentType" name="some-radios" value="INDIVIDUAL">Bireysel Üye</b-form-radio>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addBranchUser">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editUserPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Kullanıcı Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Sınıf ?" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(branch) => branch.id" :options="branchs" v-model="updateUserRequest.branchId" />
                </b-form-group>

                <b-form-group label="Kullanıcı Adı" label-for="aT">
                    <b-form-input name="aT" id="aT" type="text" v-model="updateUserRequest.firstName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Soyadı" label-for="bT">
                    <b-form-input name="bT" id="bT" type="text" v-model="updateUserRequest.lastName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Numarası" label-for="cT">
                    <b-form-input id="cT" type="text" v-model="updateUserRequest.idNumber" />
                </b-form-group>

                <b-form-group label="Okul Numarası" label-for="cT">
                    <b-form-input id="cT" type="text" v-model="updateUserRequest.schoolNumber" />
                </b-form-group>

                <b-form-group label="E-Posta Adresi" label-for="dT">
                    <b-form-input id="dT" type="email" v-model="updateUserRequest.email" />
                </b-form-group>

                <b-form-group label="Telefon Numarası" label-for="zT">
                    <b-input-group>
                        <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
                        <cleave id="zT" v-model="updateUserRequest.gsmNumber" trim class="form-control" :raw="false" :options="{
                  phone: true,
                  phoneRegionCode: 'TR',
                }" placeholder="5455948640" />
                    </b-input-group>
                </b-form-group>

                <b-form-group label="Şifre" label-for="kT">
                    <b-form-input id="kT" type="password" v-model="updateUserRequest.password" />
                </b-form-group>

                <b-form-group label="Cinsiyesi" label-for="from">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(gender) => gender.name" label="name" :options="gender" v-model="updateUserRequest.gender" />
                </b-form-group>
                <b-form-group label="Bilgilendirme" label-for="from">
                    <b-form-checkbox v-model="updateUserRequest.campaignEmail">
                        E-Posta Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="updateUserRequest.campaignSms">
                        Sms Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="updateUserRequest.campaignPhone">
                        Aranarak Bilgilendirme
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Kullanıcı Durumu" label-for="from">
                    <b-form-checkbox v-model="updateUserRequest.isActive">
                        Giriş Yapabilir
                    </b-form-checkbox>
                </b-form-group>
               <b-form-group label="Bilgilendirme" label-for="some-radios">
                     <b-form-radio v-model="updateUserRequest.studentType"  name="some-radios" value="0">Kurumsal Üye</b-form-radio>
                     <b-form-radio v-model="updateUserRequest.studentType" name="some-radios" value="INDIVIDUAL">Bireysel Üye</b-form-radio>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateBranchUser">
                        Güncelle
                    </b-button>
                    <b-button variant="danger" class="mr-2" @click="removeUser(updateUserRequest)">
                        Öğrenciyi Sil
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>
    <b-sidebar sidebar-class="sidebar-lg" width="30%" bg-variant="white" shadow backdrop no-header right v-model="editStudentLessonsPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Öğrenci Dersleri Düzenle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <div class="card-transaction px-2 py-1">
                <div class="transaction-item" @click="addStudentLessonPanelModal = true">
                    <b-media no-body>
                        <b-media-aside>
                            <b-avatar rounded size="42" variant="light-success">
                                <feather-icon size="18" icon="PlusIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="transaction-title">Yeni Ders Ekle</h6>
                            <small>Öğrencinin Ders Ataması </small>
                        </b-media-body>
                    </b-media>
                    <div class="font-weight-bolder text-danger"></div>
                </div>
               


                  <div class="transaction-item" v-for="modul in selectedStudentLessons" :key="modul.id">
                    <b-media no-body>
                        <b-media-aside>
                            <b-avatar rounded size="42" variant="light-primary">
                                <feather-icon size="18" icon="PocketIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="transaction-title">
                                {{ modul.name }}
                            </h6>
                            
                        </b-media-body>
                    </b-media>
                    <div class="font-weight-bolder text-success">
                        <b-button variant="flat-danger" class="btn-icon" @click="deleteStudentLesson(modul.id)">
                            <feather-icon icon="DeleteIcon" />
                        </b-button>
                     
                    </div>
                </div>


         
            </div>

        </template>
    </b-sidebar>

    <b-modal title="Ders Ekle" ok-title="Ekle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="addStudentLessonPanelModal" @ok="addLessonStudent">
        <b-form>
            <b-form-group label="Eklemek İstediğiniz Ders" label-for="vue-select">
                <v-select id="vue-select" v-model="LessonSelectId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(lesson) => lesson.id" label="name" :options="lessonsFiltered" />
            </b-form-group>
        </b-form>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </b-modal>
    
    <b-modal v-model="showInfo" title="Excell Kullanıcı Yükleme" hide-footer>
        <p>
            Örnek Şablonu <a href="https://api.uzaktanokulsistemi.com/sablon/SABLON-USER.xlsx" target="_blank">buradan</a> indirebilirsiniz.<br />
            <b>Not: Excelde Öğrenci sınıfı girilirken alt sınıflardan "SINIF KODU" girilmesi gerekmektedir!</b>
        </p>
        <p>
            <b-button block variant="primary" class="font-weight-bolder mb-12" @click="$refs.files.click()">YÜKLE</b-button>
        </p>
    </b-modal>
    <b-modal v-model="showLoading" centered hide-footer hide-header>
        <p class="text-center">
            <b>Öğrenciler sisteme yükleniyor!<br>Lütfen Bekleyin...</b>
        </p>
    </b-modal>
    <input style="display:none" type="file" id="files" ref="files" v-on:change="handleFileUploads()" />
    <b-modal v-model="showError" title="İşlem Logları" hide-footer>
        <em class="my-4" v-for="(i,key) in logs" :key="key">
            {{i}}
            <br />
        </em>
    </b-modal>

</b-card>
</template>

<script>
import {
    BCard,
    BModal ,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.tr'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
export default {
    components: {
        VueGoodTable,
        Cleave,
        BFormCheckbox,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BModal ,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BInputGroupPrepend,
        BInputGroup,
    BFormRadio
    },
   computed: {
 
        lessonsFiltered : function () {
         var _this=this;
          return this.lessons.filter(function(lesson){
                
                
                for (let index = 0; index < _this.selectedStudentLessons.length; index++) {
                    const _lesson = _this.selectedStudentLessons[index];
                    if(_lesson.id==lesson.id){ return false;}
                }  
               return true;
          })
        }
    },
    data() {
        return {
           childClass: [],
           lessons:[],
           LessonSelectId:"",
           selectedUser:[],
           selectedStudentLessons:[],
           addStudentLessonPanelModal:false,
            gender: [{
                name: "ERKEK"
            }, {
                name: "KADIN"
            }, {
                name: "BELİRTİLMEMİŞ"
            }],
            fields: [
                { field: 'createdAt', label: 'Kayıt Tarihi' },
                { field: 'isActive', label: 'Durum' },
                {
                    field: 'firstName',
                    label: 'Adı',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'İsme Göre Ara',
                    },
                },
                {
                    field: 'lastName',
                    label: 'Soyadı',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Soyisme Göre Ara',
                    },
                },
                {
                    field: 'email',
                    label: 'E-Posta Adresi',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'E-Posta Adresine Göre Ara',
                    },
                },
                {
                    field: 'schoolNumber',
                    label: 'Okul Numarası',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Okul Numarasına Göre Ara',
                    },
                },
                {
                    field: 'gsmNumber',
                    label: 'Telefon Numarası',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Telefona Göre Ara',
                    },
                },
                {
                    field: 'class',
                    label: 'Şube',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Şubeye Göre Ara',
                    },
                },
                { field: 'gender', label: 'Cinsiyet' },
                { field: 'actions', label: 'İşlemler' },
            ],
            users: [],
            branchs: [],
            addUserPanel: false,
            editUserPanel: false,
            editStudentLessonsPanel:false,
            addUserRequest: {
                studentType:"INSTITUTIONAL",
                branchId: 0,
                password: "",
                email: "",
                gsmNumber: "",
                firstName: "",
                lastName: "",
                gender: "",
                idNumber: "",
                schoolNumber:"",
                campaignEmail: true,
                campaignSms: true,
                campaignPhone: true
            },
            updateUserRequest: {
                branchId: 0,
                studentType:"INSTITUTIONAL",
                studentId: 0,
                password: "",
                email: "",
                gsmNumber: "",
                firstName: "",
                lastName: "",
                gender: "",
                idNumber: "",
                schoolNumber:"",
                campaignEmail: true,
                campaignSms: true,
                campaignPhone: true,
                isActive: false
            },
            logs: [],
            showError: false,
            showLoading: false,
            showInfo: false
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addUserPanel = true;
        });
        this.$root.$on('AddUploadClick', () => {
            this.showInfo = true;
            // console.log(this.$refs);
            //this.$refs.files.click();
        });

           

    },
    created() {
        this.getData();
        this.getBranch();
        this.getLessons();
    
    },
    methods: {
            deleteStudentLesson(deleteslessonid) {

            this.$confirm({
                title: 'Sil',
                message: `Dersi Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        await this.$http.delete("Branch/DeleteStudentLesson/"  +  this.selectedUser.id  + "/" + deleteslessonid);
                        await this.editStudentLessonsPanelShow(this.selectedUser);
                        //this.editStudentLessonsPanel= false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'İşlem',
                                icon: 'BellIcon',
                                text: "İşlem Başarılı Ders Silindi",
                                variant: 'success'
                            },
                        });
                    }
                }
            });
        },
        async editStudentLessonsPanelShow(user){
            this.editStudentLessonsPanel = true;
            this.selectedUser=user;
           
            var data = await this.$http.get("Branch/StudentLesson/" + user.id);
            //console.log(data);
             this.selectedStudentLessons=data.data.data.filter(x=> x.isDeleted ==false  );
        },
        async addLessonStudent(){
            //console.log(this.selectedUser);
                   this.$http.post("Branch/AddStudentLesson", {
                    studentId:this.selectedUser.id,
                    LessonId: this.LessonSelectId
                }).then((data) => {
                 
                    this.getData();
                    this.addStudentLessonPanelModal = false;
                      this.editStudentLessonsPanelShow(this.selectedUser);
                   // this.editStudentLessonsPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Ders Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });      
        },

        async getLessons() {
            var lessons = await this.$http.get("Branch/Lessons");
            this.lessons = lessons.data.data;
        },
        handleFileUploads() {
            var formData = new FormData();
            this.showInfo = false;
            this.showLoading = true;
            formData.append("file", this.$refs.files.files[0]);
            this.$http.post("Branch/StudentUpload", formData).then((data) => {
                    this.logs = data.data.data;
                    this.showLoading = false;
                    this.getData();
                    this.showError = true;
                    this.$refs.files.value = null;
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });

        },
        async getData() {
            var users = await this.$http.get("Branch/Students");
            this.users = users.data.data;
        },
        myTrim(x) {
            var replaced = x.replace(/\s+/g, '');
            return replaced;
        },
        async getBranch() {
            var branchs = await this.$http.get("Branch/ChildClasses");
            this.branchs = branchs.data.data;
        },
        removeUser(user) {
            //console.log(user);

            this.$confirm({
                title: 'Sil',
                message: `${user.firstName} ${user.lastName} Adlı Öğrenciyi Silmek İstediğinizden Emin misiniz? <b style='color:red'> Silindiğinde öğrencinin bütün dataları temizlenecektir bir daha bu öğrenciye ait raporlamalar yapılmıyacaktır!!!</b>`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        await this.$http.delete("Branch/RemoveStudent/" + user.studentId);
                        this.editUserPanel = false;
                        this.getData();
                    }
                }
            });
        },
        editUser(user) {
            //console.log(user);
            this.updateUserRequest = {
                branchId: user.classId,
                studentId: user.id,
                studentType: user.userPurchaseType,
                password: "",
                email: user.email,
                gsmNumber: user.gsmNumber,
                firstName: user.firstName,
                lastName: user.lastName,
                gender: user.gender,
                idNumber: user.idNumber,
                campaignEmail: user.campaignEmail,
                campaignSms: user.campaignSms,
                campaignPhone: user.campaignPhone,
                isActive: user.isActive,
                schoolNumber:user.schoolNumber
            };
            this.editUserPanel = true;
        },
        updateBranchUser() {
            var request = this.updateUserRequest;
            this.$http.put("Branch/UpdateStudent", {
                    classId: request.branchId,
                    password: request.password,
                    email: request.email,
                    gsmNumber: this.myTrim(request.gsmNumber),
                    firstName: request.firstName,
                    userPurchaseType: request.studentType,
                    lastName: request.lastName,
                    gender: request.gender,
                    idNumber: request.idNumber,
                    campaignEmail: request.campaignEmail,
                    campaignSms: request.campaignSms,
                    campaignPhone: request.campaignPhone,
                    studentId: request.studentId,
                    isActive: request.isActive,
                    schoolNumber:request.schoolNumber
                }).then((data) => {
                    //console.log(data);
                    this.getData();
                    this.editUserPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kullanıcı Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        addBranchUser() {
            var request = this.addUserRequest;
            this.$http.post("Branch/AddStudent", {
                    classId: request.branchId,
                    password: request.password,
                    email: request.email,
                    userPurchaseType:request.studentType,
                    gsmNumber: this.myTrim(request.gsmNumber),
                    firstName: request.firstName,
                    lastName: request.lastName,
                    gender: request.gender,
                    idNumber: request.idNumber,
                    schoolNumber:request.schoolNumber,
                    campaignEmail: true,
                    campaignSms: true,
                    campaignPhone: true
                }).then((data) => {
                    //console.log(data);
                    this.getData();
                    this.addUserPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kullanıcı Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
